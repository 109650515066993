import React from "react";

const Text = {
  copy,
  copyText,
  textToBadge,
  nl2br,
};
export default Text;

function copy(elementId) {
  var copyText = document.getElementById(elementId);

  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  document.execCommand("copy");
}

function copyText(elementId) {
  var copyText = document.getElementById(elementId);
  navigator.clipboard.writeText(copyText.textContent);
}

function textToBadge(text = null) {
  if (text == null) {
    return "";
  }
  var badges = text.split(",");
  return badges.map((b) => <span class="badge">{b}</span>);
}

function nl2br(text) {
  return text.split("\n").map((item, key) => {
    return <p key={key}>{item}</p>;
  });
}
