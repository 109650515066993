import React, { useContext, useEffect } from "react";
import { Switch, BrowserRouter as Router, useHistory } from "react-router-dom";
import { AuthRoute, PrivateRoute, WebsiteRoute } from "./RouteLayout";
import Register from "../components/auth/Register";
import Recovery from "../components/auth/Recovery";
import Reset from "../components/auth/Reset";
import Login from "../components/auth/Login";
import Logout from "../components/auth/Logout";
import Home from "../components/Home";
import SearchPeople from "../components/search/SearchPeople";
import Dashboard from "../components/user/Dashboard";
import Account from "../components/user/Account";
import ChangePassword from "../components/user/ChangePassword";
import Profile from "../components/user/Profile";
import UserCredits from "../components/user/Credits";
import UserContacts from "../components/user/UserContacts";
import Terms from "../components/website/Terms";
import Privacy from "../components/website/Privacy";
import Business from "../components/website/Business";

import SearchChallenges from "../components/challenges/SearchChallenges";
import SearchSolutions from "../components/challenges/SearchSolutions";
import ShowChallenge from "../components/challenges/ShowChallenge";
import ShowSolution from "../components/challenges/ShowSolution";

import UserMessages from "../components/messages/UserMessages";
import JobsByUser from "../components/jobs/JobsByUser";

import UserPayments from "../components/payments/UserPayments";
import PaymentCallback from "../components/payments/PaymentCallback";

import CreateJob from "../components/jobs/CreateJob";
import SearchJobs from "../components/jobs/SearchJobs";

import PdfUploader from "../components/resumes/PdfUploader";

import { AppContext } from "../components/context/AppContext";
import UsersRanking from "../components/search/UsersRanking";

export const AppRoute = () => {
  const [context, setContext] = useContext(AppContext);

  return (
    <Router>
      <Switch data="data">
        <AuthRoute
          path="/auth/login"
          title="Iniciar sesión"
          component={Login}
          render={(props) => <Login {...props} />}
        />
        <AuthRoute
          path="/auth/register"
          title="Crear una cuenta"
          component={Register}
          render={(props) => <Register {...props} />}
        />
        <AuthRoute
          path="/auth/recovery"
          title="Recuperar clave"
          component={Recovery}
          render={(props) => <Recovery {...props} />}
        />
        <AuthRoute
          path="/auth/reset/:token"
          title="Resetear clave"
          component={Reset}
          render={(props) => <Reset {...props} />}
        />

        <PrivateRoute exact path="/auth/logout">
          <Logout />
        </PrivateRoute>

        <PrivateRoute path="/user/dashboard" title="Dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/user/account" title="Mi cuenta">
          <Account />
        </PrivateRoute>
        <PrivateRoute path="/user/credits" title="Créditos">
          <UserCredits />
        </PrivateRoute>
        <PrivateRoute path="/user/contacts" title="Contactos">
          <UserContacts />
        </PrivateRoute>
        <PrivateRoute path="/messages/index" title="Mensajes">
          <UserMessages />
        </PrivateRoute>

        <PrivateRoute path="/jobs/index" title="Mis empleos">
          <JobsByUser />
        </PrivateRoute>

        <PrivateRoute path="/payments/index" title="Pagos">
          <UserPayments />
        </PrivateRoute>
        <PrivateRoute path="/payments/callback" title="Callback Pagos">
          <PaymentCallback />
        </PrivateRoute>

        <PrivateRoute path="/user/changepassword" title="Cambiar mi clave">
          <ChangePassword />
        </PrivateRoute>

        <PrivateRoute path="/jobs/create" title="Publicar un empleo">
          <CreateJob />
        </PrivateRoute>

        <PrivateRoute path="/resumes/upload" title="Subir un currículum">
          <PdfUploader />
        </PrivateRoute>

        <WebsiteRoute
          path="/jobs/search"
          title="Empleo Para Programadores JUNIOR"
        >
          <SearchJobs />
        </WebsiteRoute>

        <WebsiteRoute exact path="/:username" title={window.location.pathname}>
          <Profile />
        </WebsiteRoute>

        <WebsiteRoute exact path="/" title="HolaJuniors - Programadores Junior">
          <Home />
        </WebsiteRoute>
        <WebsiteRoute
          exact
          path="/search/people"
          title="Buscando programadores Junior"
        >
          <SearchPeople />
        </WebsiteRoute>
        <WebsiteRoute
          exact
          path="/search/ranking"
          title="Buscando programadores Junior"
        >
          <UsersRanking />
        </WebsiteRoute>

        <WebsiteRoute
          exact
          path="/challenges/search"
          title="Desafíos y práctica de programación"
        >
          <SearchChallenges />
        </WebsiteRoute>
        <WebsiteRoute
          exact
          path="/challenges/:slug"
          title="Desafíos y práctica de programación"
        >
          <ShowChallenge />
        </WebsiteRoute>
        <WebsiteRoute
          exact
          path="/solutions/search"
          title="Soluciones de programación"
        >
          <SearchSolutions />
        </WebsiteRoute>
        <WebsiteRoute
          exact
          path="/solutions/:id"
          title="Soluciones a los proyectos de programación"
        >
          <ShowSolution />
        </WebsiteRoute>

        <WebsiteRoute path="/website/terms" title="Términos y condiciones">
          <Terms />
        </WebsiteRoute>
        <WebsiteRoute path="/website/privacy" title="Privacidad">
          <Privacy />
        </WebsiteRoute>
        <WebsiteRoute
          path="/website/business"
          title="Contratar Programadores Junior"
        >
          <Business />
        </WebsiteRoute>
      </Switch>
    </Router>
  );
};

export default AppRoute;
